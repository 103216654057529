
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { CustomerService } from '@/services/customer-service';
import { OrdersService } from '@/services/orders-service';
import { Item } from '@/store/models/ItemModel';
import RushIcon from '@/components/Icons/RushIcon.vue';
import RollDirectionAlert from '@/components/RollDirectonAlert.vue';
import {
  OrderlineDetails,
  OrderlinePurchaseStateTypes,
  RushOption
} from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Address } from '@/store/models/AddressModel';
@Component({
  components: { RushIcon, RollDirectionAlert }
})
export default class OrderForm extends Vue {
  /* Properties */
  @Prop() providedPO!: string;
  @Prop() providedItem!: Item;
  /* Store Actions */
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  @Getter('getCustomer', { namespace: 'customer' })
  currentCustomer: any;
  /* Watchers */
  @Watch('search')
  async onSearchChanged(val: string) {
    // Items have already been loaded
    if (this.availablePOs.length > 0) return;
    // Items have already been requested
    if (this.isSearching) return;
    this.isSearching = true;
    // Lazily load input items
    this.getAllCustomerOrderPOs();
  }
  @Watch('selectedPO')
  async onPOChange(val: any) {
    if (!val) {
      this.createPO();
    }
    if (val && this.doesPOExists()) {
      this.getAllOpenOrderlinesByPO(val);
    } else {
      this.$emit('orderId', '');
      this.$emit('po', val);
    }
  }
  @Watch('quantity')
  onQuantityChanged() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      this.generateNewOrderline();
    }, this.doneTypingInterval);
  }
  @Watch('rushOption')
  onRushOptionChange(newVal: RushOption, oldVal: RushOption) {
    if (!this.isGeneratingNewOrderline && newVal.PublicId != oldVal.PublicId)
      this.generateNewOrderline();
  }
  /* Data */
  item: Item = new Item();
  availablePOs: any[] = [];
  addresses: Address[] = [];
  selectedAddress: any = '';
  selectedPO: any = '';
  shouldShowAlert: boolean = false;
  isSearching: boolean = false;
  orderlines: OrderlineDetails[] = [];
  newOrderline: OrderlineDetails = new OrderlineDetails();
  rushOptions: RushOption[] = [];
  rushOption: RushOption = new RushOption();
  rushHint: string = '';
  search: any = null;
  selectedRollDirectionId: number | null = null;
  quantity: number | null = null;
  isUpdatingRollDirection: boolean = false;
  isGeneratingNewOrderline: boolean = false;
  defaultCustomerRollDirection!: number;
  typingTimer: any;
  doneTypingInterval: number = 500;
  rollDirectionOptions: any = [
    {
      title: '#4 unwind',
      description: 'Left side of label dispenses first. Wound Outside',
      src: require('@/assets/4unwind.svg'),
      value: 4
    },
    {
      title: '#3 unwind',
      description: 'Right side of label dispenses first. Wound Outside',
      src: require('@/assets/3unwind.svg'),
      value: 3
    }
  ];
  /* Utility Functions */

  doesPOExists() {
    return this.availablePOs.some(p => p == this.selectedPO) || !this.availablePOs[0];
  }
  async createPO() {
    const response = await OrdersService.CreateDefaultPo();
    this.selectedPO = response.data;
  }

  async generateNewOrderline() {
    if (!this.canGenerateOrderline()) return;
    this.isGeneratingNewOrderline = true;
    this.$emit('isGeneratingOrderline', true);
    const model = ConversionHelper.generateNewOrderlineModel(
      this.item.Id,
      Number(this.quantity),
      this.selectedAddress.Id,
      this.selectedPO
    );
    if (!this.currentCustomer) {
      await this.getCurrentCustomerStore();
    }
    model.RollDirectionId = this.selectedRollDirectionId!;
    const response = await OrdersService.GenerateOrderlineDefaults(model);
    const newOrderline = new OrderlineDetails(response.data);
    newOrderline.OrderLineNumber =
      (this.orderlines.length >= 1
        ? Math.max.apply(
            Math,
            (this.orderlines as OrderlineDetails[]).map(ol => {
              return ol.OrderLineNumber;
            })
          )
        : 0) + 1;
    newOrderline.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
    if (this.newOrderline.Id) {
      this.orderlines[0] = newOrderline;
    } else {
      this.orderlines.unshift(newOrderline);
    }
    this.newOrderline = newOrderline;
    await this.LoadRushFeeValues();
    this.$emit('newOrderlineGenerated', this.orderlines);
    this.$emit('isGeneratingOrderline', false);
    this.isGeneratingNewOrderline = false;
  }

  async getAllOpenOrderlinesByPO(po: string) {
    const model = {
      Pos: [po],
      page: 0
    };
    const response = await OrdersService.GetOrderlinesByPO(model);
    if (response.data && response.data[0])
      this.$emit('orderId', response.data[0].OrderId);
    let openOrderlines = response.data.filter((o: any, i: number) => o.Status == 'OPEN');
    this.orderlines.concat(openOrderlines);
    this.generateNewOrderline();
  }
  async checkForBadRollDirections() {
    this.shouldShowAlert = false;
    this.selectedRollDirectionId = this.selectedAddress.IsCustomerPickup
      ? this.defaultCustomerRollDirection
      : this.selectedAddress.Options.RollDirectionId;
    this.shouldShowAlert = this.selectedRollDirectionId === 10;
  }
  canPlaceOrder() {
    return (
      (this.selectedAddress &&
        this.selectedAddress.Id &&
        this.rushOption.DayValue &&
        this.quantity &&
        !this.isUpdatingRollDirection &&
        !this.isGeneratingNewOrderline) ||
      this.shouldShowAlert
    );
  }
  canGenerateOrderline() {
    return !!this.item.Id && !!this.quantity && !!this.selectedPO;
  }
  onRushSelection() {
    this.orderlines[0].RushOption = this.rushOption;
    this.orderlines[0].DueDate = new Date(this.rushOption.DeliveryDate);
    this.orderlines[0].RushFee = this.rushOption.RushFee;
    this.orderlines[0].IsARush = this.rushOption.RushFee > 0;
    // this.GeneratePricingData();
  }
  /* Loaders */
  async getAllCustomerOrderPOs() {
    try {
      const response = await OrdersService.GetAllCustomerOrderPOs();
      this.availablePOs = response.data;
    } catch (err) {
      SetSnackBar(
        'There was an error, please refresh your page, or contact techincal support if error persists'
      );
    } finally {
      this.isSearching = false;
    }
  }

  async getAllCustomerAddresses() {
    try {
      const response = await CustomerService.GetShippingAddressesFull();
      this.addresses = response.data.map((a: any) => new Address(a));
      this.selectedAddress = this.addresses.find(a => a.IsDefault) || this.addresses[0];
    } catch (err) {
      SetSnackBar(
        'There was an error loading your shipping addresses, please refresh your page, or contact techincal support if error persists'
      );
    }
  }

  async LoadRushFeeValues() {
    if (this.orderlines) {
      const response = await OrdersService.GetRushFeeValues(
        ConversionHelper.convertOrderlineToAddModel(this.orderlines[0])
      );
      this.rushOptions = response.data.filter((d: any) => d.IsAvailable);
      this.rushOptions = this.rushOptions.map(r => new RushOption(r));
      if (!this.rushOption.PublicId) {
        this.rushOption = this.rushOptions[0];
      } else {
        this.rushOption = this.rushOptions.find(
          r => r.PublicId == this.rushOption.PublicId
        )!;
      }
      this.orderlines[0].RushOption = this.rushOption;
      if (!this.item.IsApproved) {
        this.rushHint =
          'If you would like to expedite this item, please approve it first';
      }
    }
  }

  async getCurrentCustomer() {
    if (!this.currentCustomer.Name) await this.getCurrentCustomerStore();
    let currentCustomer = this.currentCustomer;
    this.defaultCustomerRollDirection = currentCustomer.Options.RollDirection.Position;
  }
  /* Mounted */
  mounted() {
    if (this.providedItem) this.item = this.providedItem;
    this.getAllCustomerAddresses();
    this.getCurrentCustomer();
    this.createPO();
  }
  async setData(orderline: OrderlineDetails) {
    this.selectedAddress = orderline.Address;
    this.selectedPO = orderline.PO;
    this.quantity = orderline.Quantity;
    this.rushOption = orderline.RushOption;
  }
  /* Created */
  /* Emmited Functions */
  updateSelectedRollDirectionId(rollDirectionId: number) {
    this.selectedRollDirectionId = rollDirectionId;
  }
  closeAlert() {
    this.shouldShowAlert = false;
  }
}
