
import { DateHelper } from '@/helpers/DateHelper';
import { GetDisplayDate } from '@/helpers/ObjectHelper';
import { StringHelper } from '@/helpers/StringHelper';
import { OrderlineDetails } from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import J1LDateIcon from '@/components/J1LDateIcon.vue';
import J1lImg from '@/components/J1lImg.vue';
import RushIcon from '@/components/Icons/RushIcon.vue';
import AdminService from '@/services/admin-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
@Component({
  components: {
    J1LDateIcon,
    RushIcon,
    J1lImg
  }
})
export default class OrderZeroOutDialog extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  orderlines: OrderlineDetails[] = [];
  reason: string = 'Production Error';
  /* Computed */
  /* Async Functions */
  async zeroOutOrderlines() {
    this.isLoading = true;
    try {
      let orderlineIds = this.orderlines
        .filter(orderline => orderline.IsSelected)
        .map(orderline => orderline.Id);
      await AdminService.ZeroOutOrderlines(orderlineIds, this.reason);
      if (this.orderlines.every(ol => ol.IsSelected)) {
        this.orderlines[0].RefreshAll = true;
        this.$emit('orderline-zeroed-out', this.orderlines[0]);
      } else {
        for (let orderline of this.orderlines.filter(ol => ol.IsSelected)) {
          setTimeout(() => {
            this.$emit('orderline-zeroed-out', orderline);
          }, 500);
        }
      }
      SetSnackBar('Zeroed out orderlines successfully');
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isLoading = false;
    this.closeDialog();
  }
  /* Utility Functions */
  openDialog(orderlines: OrderlineDetails[]) {
    this.orderlines = orderlines;
    this.orderlines.forEach(orderline => {
      orderline.IsSelected = orderline.HasBeenZeroedOut;
    });
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  getTextColor(status: string) {
    return StringHelper.getStatusTextColorClass(status);
  }
  getBackgroundColorByStatus(status: string) {
    return StringHelper.getStatusTextBackgroundColorClass(status);
  }
  getBorderColorByStatus(status: string) {
    return StringHelper.getStatusTextBorderColorClass(status);
  }
  shouldUseNullDate(orderline: OrderlineDetails) {
    let statuses = ['Production hold', 'Processing Error(s)', 'Waiting on Codes'];
    return statuses.includes(orderline.OrderStatus.OrderStatusDisplay);
  }
  GetDisplayDate(orderline: OrderlineDetails) {
    return GetDisplayDate(orderline);
  }
  GetShipVerbiage(orderline: OrderlineDetails) {
    return DateHelper.GetShipVerbiage(orderline);
  }
  backgroundColor(i: number) {
    return i % 2 === 0 ? 'card-light-grey-background' : 'card-dark-grey-background';
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
