
import { MathHelper } from '@/helpers/MathHelper';
import { OrderBreakdown } from '@/store/models/OrderlineModel';
import { PaymentProfile } from '@/store/models/PaymentModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class OrderSummaryBreakdown extends Vue {
  /* Properties */
  @Prop({ required: true, default: new OrderBreakdown() })
  OrderSummaryBreakdown!: OrderBreakdown;
  @Prop()
  PaymentMethodProfile!: PaymentProfile;
  @Prop({ default: 'Invoice' }) OrderBreakdownTitle!: string;
  @Prop() IsLoading!: boolean;
  @Prop() IsInvoice!: boolean;
  @Prop({ default: true }) ShouldIncludeProcessingFee!: boolean;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Utility Functions */
  get extendedTotal() {
    let returnValue = MathHelper.Truncate(this.OrderSummaryBreakdown.ExtendedPrice, 2);
    let total = returnValue - this.OrderSummaryBreakdown.DiscountValue;
    if (!this.IsInvoice) {
      total +=
        this.OrderSummaryBreakdown.SetUpFee +
        this.OrderSummaryBreakdown.RushFee +
        this.OrderSummaryBreakdown.ExtraRollFee +
        this.OrderSummaryBreakdown.ShippingFee;
    }

    if (
      this.OrderSummaryBreakdown.Subtotal &&
      total != this.OrderSummaryBreakdown.Subtotal
    ) {
      console.log(
        `added total of ${total} does not match Subtotal ${
          this.OrderSummaryBreakdown.Subtotal
        } diff ${this.OrderSummaryBreakdown.Subtotal - total}`
      );
      returnValue += this.OrderSummaryBreakdown.Subtotal - total;
    }
    return returnValue;
  }
  get processingFee() {
    let shouldIncludeProcessingFee = this.ShouldIncludeProcessingFee;
    if (this.IsInvoice) {
      if (this.PaymentMethodProfile?.IsABankAccount()) {
        shouldIncludeProcessingFee = false;
      }
    } else {
      if (
        this.PaymentMethodProfile?.IsNet ||
        this.PaymentMethodProfile?.IsABankAccount()
      ) {
        shouldIncludeProcessingFee = false;
      }
    }
    if (this.OrderSummaryBreakdown.Subtotal == 0)
      this.OrderSummaryBreakdown.Subtotal = this.calculateSubTotal();
    return shouldIncludeProcessingFee ? this.OrderSummaryBreakdown.Subtotal * 0.025 : 0;
  }
  get subtotal() {
    return (
      (!this.OrderSummaryBreakdown.Subtotal
        ? this.calculateSubTotal()
        : this.OrderSummaryBreakdown.Subtotal) +
      (this.ShouldIncludeProcessingFee ? this.processingFee : 0)
    );
  }
  calculateSubTotal() {
    return (
      MathHelper.Truncate(this.OrderSummaryBreakdown.ExtendedPrice, 2) +
      this.OrderSummaryBreakdown.SetUpFee +
      this.OrderSummaryBreakdown.RushFee +
      this.OrderSummaryBreakdown.ExtraRollFee +
      this.OrderSummaryBreakdown.ShippingFee -
      this.OrderSummaryBreakdown.DiscountValue
    );
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
