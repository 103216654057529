
export class OrderNote {
  NoteValue: string = '';
  DisplayName: string = '';
  CreationDate: string = '';
  NoteId: string = '';
}
export class OrderNoteModel {
  NoteValue: string = '';
  OrderlineId: string = '';
  NoteId: string = '';
}
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import AdminService from '@/services/admin-service';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class OrderNoteManagement extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  notes: any[] = [];
  isAddingNewNote: boolean = false;
  isSavingNewNote: boolean = false;
  newNoteValue: string = '';
  orderlineId: string = '';
  /* Computed */
  /* Async Functions */
  async saveNote(existingNote: OrderNote = null) {
    this.isSavingNewNote = true;
    try {
      let model: OrderNoteModel = new OrderNoteModel();
      model.OrderlineId = this.orderlineId;
      model.NoteValue = existingNote ? existingNote.NoteValue : this.newNoteValue;
      model.NoteId = existingNote?.NoteId;
      const { data } = await AdminService.saveOrderlineNote(model);
      if (data) {
        this.notes.unshift(data);
      }
      this.resetNewNote();
      SetSnackBar('Added new note successfully');
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isSavingNewNote = false;
  }
  /* Utility Functions */
  openDialog(orderlineId: string) {
    this.dialog = true;
    this.isAddingNewNote = false;
    this.notes = [];
    this.orderlineId = orderlineId;
    this.getAllNotesForOrderline(orderlineId);
  }
  closeDialog() {
    this.dialog = false;
  }
  resetNewNote() {
    this.newNoteValue = '';
    this.isAddingNewNote = false;
    this.notes = this.notes.filter(n => !n.AddNewNote);
    this.notes.push({
      AddNewNote: true,
      NoteValue: '',
      DisplayName: '',
      CreationDate: ''
    });
  }
  /* Loaders */
  async getAllNotesForOrderline(orderlineId: string) {
    const { data } = await AdminService.getAllNotesForOrderline(orderlineId);
    this.notes = data;
    this.resetNewNote();
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
