
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Item } from '../store/models/ItemModel';
import { Orderline } from '../store/models/OrderlineModel';
import { ColorHelpers } from '@/helpers/ColorHelper';
import J1lImg from '@/components/J1lImg.vue';
import CustomerPaymentDialog from '@/components/CustomerPaymentDialog.vue';
import { OrdersService } from '@/services/orders-service';

@Component({
  components: { J1lImg, CustomerPaymentDialog }
})
export default class extends Vue {
  $refs!: {
    J1lImg: HTMLFormElement;
  };
  /* Properties */

  /* Store Actions */
  @Prop()
  orderlineGroup!: any;
  @Prop()
  po!: string;
  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: boolean) {
    if (!val) {
      this.closeDialog();
    }
  }

  /* Data */
  dialog: boolean = false;
  paymentDialog: boolean = false;
  packlists: any = [];
  plnumber: string = '';
  isTextInputValid: boolean = false;

  isDownloadingInvoice: boolean = false;
  /* Methods */
  async openDialog() {
    this.dialog = true;
    let po = this.po ? this.po : this.orderlineGroup?.GroupByValue;
    if (po) {
      const response = await OrdersService.GetPacklistByPo(po);
      this.packlists = response.data;
    }
  }
  async downloadPacklist(packlist: any) {
    OrdersService.DownloadPacklist(packlist.PlNumber);
  }

  goToItem(item: Item) {
    let route = this.$router.resolve({
      name: 'ItemInformation',
      params: { id: item.Id }
    });
    window.open(route.href, '_blank');
  }
  goToOrderDetail() {
    this.$router.push({
      name: 'OrderView',
      params: { po: this.orderlineGroup.GroupByValue }
    });
  }
  trackPackage(packlist: any) {
    window.open(
      'https://www.ups.com/track?loc=en_US&tracknum=' +
        packlist.TrackingNumber +
        '&requester=WT/trackdetails'
    );
  }
  determineStatusColor(packlist: any) {
    if (packlist) {
      let fontcolor = ColorHelpers.getPacklistStatus(packlist.Status);
      return fontcolor;
    }
  }

  closeDialog() {
    this.dialog = false;
  }

  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
}
