
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import {
  OrderlineDetails,
  Orderline,
  OrderlinePurchaseStateTypes,
  OrderPo
} from '../store/models/OrderlineModel';
import { OrdersService } from '@/services/orders-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { areListsDifferent, getDistinctOccurences } from '@/helpers/ObjectHelper';
import OrderEditPo from '@/components/OrderEditPo.vue';
import OrderSummaryBreakdown from '@/components/OrderSummaryBreakdown.vue';
import { OrderBreakdown } from '@/store/models/OrderlineModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { AxiosError } from 'axios';
import { ConstValues } from '@shared/ConstValues';
import { DialogHelper } from '@/helpers/DialogHelper';
import { PaymentProfile } from '@/store/models/PaymentModel';
import { Confirm } from '@/helpers/AuthHelper';
import { ValidationHelper } from '@/helpers/ValidationHelper';
import { OrderlinePreflightCheck } from '@/helpers/OrderlineHelper';
import OrderlinePreflight from './OrderlinePreflight.vue';
import { MathHelper } from '@/helpers/MathHelper';
@Component({
  components: {
    OrderEditPo,
    OrderSummaryBreakdown,
    OrderlinePreflight
  }
})
export default class OrderSummary extends Vue {
  $refs!: {
    OrderEditPo: HTMLFormElement;
    OrderlinePreflight: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true })
  po!: string;
  @Prop()
  isReorder: any;
  @Prop({ required: true })
  isConfirmationSummary!: boolean;
  @Prop()
  paymentMethodProfile!: PaymentProfile;
  @Prop()
  shouldShowOrderDetailsOnly!: boolean;
  @Prop({ default: true })
  shouldGeneratePricingDataOnLoad!: boolean;
  /* Store Actions */
  @Action('ClearCurrentOrder', { namespace: 'orderlines' })
  clearCurrentOrder!: any;
  @Action('RemoveOrdersByPO', { namespace: 'orderlines' })
  removeOrdersByPO: any;
  @Action('ChangePO', { namespace: 'orderlines' })
  changePOInStore: any;
  @Action('GetShoppingCart', { namespace: 'orderlines' })
  refreshShoppingCart: any;
  @Mutation('setCurrentOrder', { namespace: 'orderlines' })
  setCurrentOrder!: any;
  @Mutation('setOrderConfirmation', { namespace: 'orderlines' })
  setOrderConfirmation!: any;

  @Getter('getCurrentOrder', { namespace: 'orderlines' })
  orderlines!: OrderlineDetails[];
  @Getter('orderlines', { namespace: 'orderlines' })
  currentOrderlines!: Orderline[];
  @Mutation('orderlinesLoaded', { namespace: 'orderlines' })
  setOrderlines: any;
  @Mutation('addPOToList', { namespace: 'orderlines' })
  addPOToList!: any;
  /* Watchers */
  @Watch('customerPO')
  onCustomerPOChanged() {
    if (!this.isReorder) {
      this.$emit('poChanged', this.customerPO, this.isReorder);
    }
  }
  @Watch('orderlines', { deep: true })
  onOrderlinesChange() {
    this.setOrderlineBreakdown();
  }
  @Watch('isCancellingOrder')
  onIsCancellingOrderChange(val: boolean) {
    if (val) this.disableSaveAndCancelLinks();
  }
  @Watch('isSavingOrder')
  onIsSavingOrderChange(val: boolean) {
    if (val) this.disableSaveAndCancelLinks();
  }

  /* Data */
  canEditPO: boolean = true;
  hasChanged: boolean = false;

  customerPO: string = '';
  changeCount: number = 0;
  originalOrder: OrderlineDetails[] = [];

  isPlacingOrder: boolean = false;
  isCheckingOut: boolean = false;
  isCancellingOrder: boolean = false;
  isAnOrderEdit: boolean = true;
  isSavingOrder: boolean = false;
  isGeneratingPricingData: boolean = false;
  isSettingPO: boolean = false;

  orderBreakdown: OrderBreakdown = new OrderBreakdown();
  /* Methods */
  isCartOrder() {
    return this.$route.query.isCartOrder === 'true';
  }
  validateAddresses() {
    for (let address of this.orderlines.map(ol => ol.Address)) {
      ValidationHelper.IsAddressValid(address);
    }
  }
  onPlaceOrderPressed() {
    this.$refs.OrderlinePreflight.runPreflightCheck(() => {
      this.placeOrder();
    });
    return;
  }
  async GeneratePricingData() {
    this.isGeneratingPricingData = true;
    if (this.orderlines[0]) {
      const response = await OrdersService.CalculatePricingData(
        this.orderlines.map(ol => ConversionHelper.convertOrderlineToAddModel(ol))
      );
      if (this.orderlines.length === response.data.length) {
        const orderlines = response.data.map((ol: any) => new OrderlineDetails(ol));
        this.setCurrentOrder(orderlines);
        this.resetFees();
        this.setOrderlineBreakdown();
        this.calculateSubTotal();
      }
    } else {
      this.resetFees();
    }
    this.isGeneratingPricingData = false;
  }
  GoToDuplicateOrder(po: string) {
    this.$router.push({
      name: 'OrderView',
      params: { po: po }
    });
    location.reload();
  }
  async saveForLater(shouldReRoute: boolean = true) {
    this.isSavingOrder = true;
    let customerOrderId = '';
    let availableOrder = this.orderlines.filter(ol => !!ol.CustomerOrderId);
    if (availableOrder[0]) customerOrderId = availableOrder[0].CustomerOrderId;
    this.orderlines.forEach(ol => (ol.OrderLineNumber = this.orderlines.indexOf(ol) + 1));
    try {
      let model = {
        IsCartOrder: this.isCartOrder(),
        Orderlines: this.orderlines.map(ol =>
          ConversionHelper.convertOrderlineToAddModel(ol)
        ),
        OrderId: customerOrderId,
        PO: this.po,
        State: this.orderlines.every(
          ol => ol.OrderlinePurchaseStateType === OrderlinePurchaseStateTypes.Create
        )
          ? 'New'
          : 'Edit',
        ShipToId: 4,
        ShouldSaveForLater: true
      };

      await OrdersService.SaveOrder(model);
      SetSnackBar('Saved Order Successfully');
      if (this.isCartOrder()) {
        this.refreshShoppingCart();
      }
      if (shouldReRoute) await this.clearCurrentOrder();
      this.addPOToList(
        new OrderPo({
          PO: this.po,
          Page: 1
        })
      );
      this.$emit('bypassNavigationGuard');
      if (shouldReRoute) this.$router.push({ name: 'Orders' });
    } catch (err) {
      SetSnackBar(
        'There was a problem saving your order, please contact technical support'
      );
    }
    this.isSavingOrder = false;
  }
  async setPO(val: string) {
    this.isSettingPO = true;
    (this.orderlines as Orderline[]).map((ol: Orderline) => (ol.PO = val));
    if (this.po !== val) {
      let oldPO = this.po;
      try {
        await OrdersService.ChangeOrderPO(oldPO, val, this.isCartOrder());
        if (val) {
          this.changePOInStore({ oldPO: oldPO, newPO: val });
          this.setCurrentOrder(this.orderlines);
          this.customerPO = val;
          const redirectList = ['OrderView', 'orderConfirmation', 'OrderDetails'];
          if (redirectList.includes(this.$route.name)) {
            this.$route.params.po = val;
            window.history.pushState(null, '', this.$router.resolve(this.$route).href);
          } else if (this.$route.name === 'OrderDetails') {
            this.$route.params.po = val;
            window.history.pushState(null, '', this.$router.resolve(this.$route).href);
          }
          SetSnackBar('Changed PO successfully');
        }
      } catch (e) {
        SetSnackBar(
          'There was an error changing your PO, please contact technical support'
        );
      }
    }
    this.isSettingPO = false;
  }
  groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  openEditPoDialog() {
    this.$refs.OrderEditPo.showDialog();
  }
  async placeOrder(didAccept: boolean = true) {
    if (!didAccept) return;
    this.isPlacingOrder = true;
    this.$emit('paymentInitiated');
    let orderId = '';
    for (let ol of this.orderlines) {
      if (ol.CustomerOrderId) {
        orderId = ol.CustomerOrderId;
        break;
      }
    }
    try {
      let model = {
        Orderlines: this.orderlines.map(ol =>
          ConversionHelper.convertOrderlineToAddModel(ol)
        ),
        PO: this.po,
        State: this.orderlines.every(
          ol => ol.OrderlinePurchaseStateType === OrderlinePurchaseStateTypes.Create
        )
          ? 'New'
          : 'Edit',
        ShipToId: 4,
        OrderId: orderId,
        PaymentMethodProfileId: this.paymentMethodProfile.PaymentProfileId,
        OrderType: `Checkout from ${this.isCartOrder() ? 'cart' : 'new order'}`,
        IsCartOrder: this.isCartOrder()
      };
      let mappedDates = this.groupBy(
        this.orderlines,
        (ol: OrderlineDetails) => ol.DueDate
      );
      let dueDates = Array.from(mappedDates).reduce((obj: any, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
      await this.setOrderConfirmation(dueDates);
      const response = await OrdersService.PlaceOrder(model);
      const newOrderlines: OrderlineDetails[] = response.data.map(
        (r: any) => new OrderlineDetails(r)
      );
      await this.clearCurrentOrder();
      this.addPOToList(
        new OrderPo({
          PO: this.po,
          Page: 1
        })
      );
      if (this.isCartOrder()) {
        this.refreshShoppingCart();
      }
      let withNewOrderline = [...newOrderlines, ...this.currentOrderlines];
      this.$router.push({ name: 'Orders', params: { hasPlacedOrder: 'true' } });
      setTimeout(() => {
        this.setOrderlines(withNewOrderline);
      }, 400);
    } catch (err) {
      const error = err as any;
      if (error.response) {
        this.$emit('paymentDeclined', error.response.data.ExceptionMessage);
      } else {
        this.$emit(
          'paymentDeclined',
          'There was a problem adding your order. Please contact technical support'
        );
      }
    }
    this.isPlacingOrder = false;
  }
  checkOut() {
    this.isCheckingOut = true;
    this.$router.push({
      name: 'orderConfirmation',
      params: { po: this.customerPO },
      query: { isCartOrder: this.$route.query.isCartOrder }
    });
    this.isCheckingOut = false;
  }
  async cancelOrder() {
    this.isCancellingOrder = true;
    try {
      const { data } = await OrdersService.GetOrderByPO(this.po);
      if (data) {
        await OrdersService.CancelOrder(
          this.po,
          this.$route.query.isCartOrder === 'true'
        );
        await this.removeOrdersByPO(this.po);
      }
      this.clearCurrentOrder();
      SetSnackBar('Order Cancelled');
      this.$emit('bypassNavigationGuard');
      this.$router.push({ name: 'Orders' });
    } catch (err) {
      if ((err as any).response.status === 400) {
        SetSnackBar(
          'Cannot cancel order that is already in production. Please contact info@just1label.com for more information'
        );
      }
    }
    this.isCancellingOrder = false;
  }
  setCanEditPO(val: boolean) {
    this.canEditPO = val;
  }
  disableSaveAndCancelLinks() {
    var aTag = document.getElementById('saveForLater');
    if (aTag) {
      aTag?.setAttribute('style', 'color: grey !important');
      aTag = document.getElementById('cancel');
      aTag?.setAttribute('style', 'color: grey !important');
    }
  }
  async doesPOExist() {
    try {
      if (!this.po) return;
      const response = await OrdersService.GetOrderByPO(this.po);
      if (!response.data) {
        this.isAnOrderEdit = false;
      }
    } catch (err) {
      this.isAnOrderEdit = false;
    }
  }
  setOrderlineBreakdown() {
    if (this.orderlines[0]) {
      this.customerPO = this.orderlines[0] ? this.orderlines[0].PO : '';
      let breakdown: OrderBreakdown = {
        TotalItems: this.orderlines.length,
        ExpeditedItems: this.orderlines.filter(ol => ol.IsARush).length,
        DieTemplates: getDistinctOccurences(this.orderlines.map(ol => ol.Die.Id)),
        Materials: getDistinctOccurences(this.orderlines.map(ol => ol.Material.Id)),
        Finishes: getDistinctOccurences(this.orderlines.map(ol => ol.Finish.Id)),
        ExtendedPrice: 0,
        SetUpFee: 0,
        RushFee: 0,
        ExtraRollFee: 0,
        ShippingFee: 0,
        VariableDataFee: 0,
        Subtotal: 0,
        HandApplyFee: 0,
        DiscountValue: 0,
        DiscountType: ''
      };
      this.orderlines.forEach(ol => {
        breakdown.ExtendedPrice += ol.ExtendedPrice;
        breakdown.SetUpFee += ol.SetupCosts;
        // breakdown.VariableDataFee += ol.Quantity * ol.VariableDataPriceEach;
        breakdown.RushFee += ol.RushFee;
        breakdown.ExtraRollFee += ol.CoreCharge;
        breakdown.HandApplyFee += ol.HandApplyFee;
        breakdown.ShippingFee += ol.ShippingCost;
        breakdown.DiscountValue += ol.DiscountValue;
      });

      this.orderBreakdown = breakdown;
    }
  }
  showDeleteConfirmation() {
    Confirm(
      () => {
        this.cancelOrder();
      },
      'Cancel Order',
      'Are you sure you want to cancel this order?',
      'No',
      'Cancel this order'
    );
  }
  get canCheckOut() {
    return (
      (this.orderlines.length > 0 &&
        areListsDifferent(this.originalOrder, this.orderlines) &&
        !this.orderlines.every(
          ol => ol.OrderlinePurchaseStateType === OrderlinePurchaseStateTypes.DoNothing
        )) ||
      this.orderlines.some(ol => ol.Status === ConstValues.PRINT_PENDING) ||
      this.isReorder
    );
  }
  get canProceedToCheckout() {
    return (
      this.orderlines.length > 0 &&
      !this.orderlines.every(
        ol => ol.OrderlinePurchaseStateType === OrderlinePurchaseStateTypes.DoNothing
      )
    );
  }
  get canPlaceOrder() {
    return (
      !!this.paymentMethodProfile?.Id &&
      !this.paymentMethodProfile.IsExpired &&
      !!this.customerPO &&
      !this.isSettingPO
    );
  }
  calculateSubTotal() {
    const orderlineTotal = this.orderlines.reduce((total, o) => {
      return total + o.Total - o.DiscountValue;
    }, 0);
    this.orderBreakdown.Subtotal = MathHelper.Truncate(orderlineTotal, 2);
  }
  get anyOrderlinesInProduction() {
    return !!this.orderlines.find(o => !o.CanEditProductionData);
  }
  get constValues() {
    return ConstValues;
  }
  calculateFee(fee: number) {
    return fee ? fee.toFixed(2) : '0.00';
  }
  resetFees() {
    let breakdown: OrderBreakdown = {
      TotalItems: this.orderlines.length,
      ExpeditedItems: this.orderlines.filter(ol => ol.IsARush).length,
      DieTemplates: getDistinctOccurences(this.orderlines.map(ol => ol.Die.Id)),
      Materials: getDistinctOccurences(this.orderlines.map(ol => ol.Material.Id)),
      Finishes: getDistinctOccurences(this.orderlines.map(ol => ol.Finish.Id)),
      ExtendedPrice: 0,
      SetUpFee: 0,
      RushFee: 0,
      ExtraRollFee: 0,
      ShippingFee: 0,
      VariableDataFee: 0,
      Subtotal: 0,
      HandApplyFee: 0,
      DiscountValue: 0,
      DiscountType: ''
    };
    this.orderBreakdown = breakdown;
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    if (this.shouldGeneratePricingDataOnLoad) {
      this.GeneratePricingData();
    }
    this.doesPOExist();
    this.customerPO = this.po;
  }
  /* Created */
}
