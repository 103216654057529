
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import ShippingService from '@/services/shipping-service';
import { Address } from '@/store/models/AddressModel';
import { OrderlineDetails } from '@/store/models/OrderlineModel';
import { PackingList } from '@/store/models/PackingList';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoadingPacklist: boolean = false;
  dialog: boolean = false;
  packlists: any[] = [];
  orderline: OrderlineDetails = new OrderlineDetails();
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  openDialog(orderline: OrderlineDetails) {
    this.orderline = orderline;
    this.dialog = true;
    this.LoadPackingList();
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  async LoadPackingList() {
    this.isLoadingPacklist = true;
    try {
      const { data } = await ShippingService.GetPackinglistByOrderlineId(
        this.orderline.Id
      );
      this.packlists = data;
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isLoadingPacklist = false;
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
