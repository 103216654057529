
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { Item } from '../store/models/ItemModel';
import { OrdersService } from '@/services/orders-service';
import { CustomerService } from '@/services/customer-service';
import DialogLayout from './DialogLayout.vue';
import RollDirectionAlert from '@/components/RollDirectonAlert.vue';
import RushIcon from '@/components/Icons/RushIcon.vue';
import { QuickDialog, AlertModel } from '../store/models/DialogModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import {
  RushOption,
  Orderline,
  OrderlineDetails,
  OrderlinePurchaseStateTypes
} from '@/store/models/OrderlineModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Clone, getObjectDiff } from '@/helpers/ObjectHelper';
import { PaymentService } from '@/services/payment-service';
@Component({
  components: { DialogLayout, RushIcon, RollDirectionAlert }
})
export default class OrderlineReorder extends Vue {
  /* Properties */
  @Prop({ required: true }) orderline!: Orderline | OrderlineDetails;
  @Prop({ required: true }) item!: Item;

  // @Prop() isOrdering: boolean = false;

  /* Store Actions */
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  @Getter('getCustomer', { namespace: 'customer' })
  currentCustomer: any;
  @Getter('orderlines', { namespace: 'orderlines' })
  loadedOrderlines!: Orderline[];
  @Mutation('orderlinesLoaded', { namespace: 'orderlines' })
  setOrderlines!: any;

  /* Watchers */

  @Watch('dialog')
  onDialogChange(val: any) {
    if (!val) {
      this.closeDialog();
    }
  }
  @Watch('selectedPO')
  async onPOChange(val: any) {
    if (!val) {
      this.createPO();
    }
    if (val && this.doesPOExists()) {
      // this.getAllOpenOrderlineByPO(val);
    }
  }

  @Watch('search')
  async onSearchChanged(val: string) {
    // Items have already been loaded
    if (this.customerOrders.length > 0) return;
    // Items have already been requested
    if (this.isSearching) return;
    this.isSearching = true;
    // Lazily load input items
    this.getAllCustomerOrder();
  }
  @Watch('quantity')
  onQuantityChanged() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      this.generateNewOrderline();
    }, this.doneTypingInterval);
  }
  @Watch('rushOption')
  onRushOptionChange() {
    if (!this.isGeneratingOrderlineDefaults) this.generateNewOrderline();
  }
  /* Data */
  debounce: boolean = false;
  dialog: boolean = false;
  data: QuickDialog | null = null;
  rushOption: RushOption = new RushOption();
  isLoading: boolean = false;
  isGeneratingOrderlineDefaults: boolean = false;
  isPlacingOrder: boolean = false;
  isSearching: boolean = false;
  totalPrice: string = '$0.00';
  selectedPO: any = '';
  search: any = null;
  entries: any[] = [];
  descriptionLimit = 50;
  addresses: any[] = [];
  selectedAddress: any = '';
  orderlineToReorder: OrderlineDetails | Orderline = new OrderlineDetails();
  orderlines!: any;
  rushOptions: RushOption[] = [];
  rushHint: string = '';
  extendedPrice: number = 0;
  variableDataFee: number = 0;
  rushFee: number = 0;
  setUpFee: number = 0;
  extraRollFee: number = 0;
  typingTimer: any;
  doneTypingInterval: number = 500;
  rollDirectionOptions: any = [
    {
      title: '#4 unwind',
      description: 'Left side of label dispenses first. Wound Outside',
      src: require('@/assets/4unwind.svg'),
      value: 4
    },
    {
      title: '#3 unwind',
      description: 'Right side of label dispenses first. Wound Outside',
      src: require('@/assets/3unwind.svg'),
      value: 3
    }
  ];
  selectedRollDirection: number | null = null;
  shouldShowAlert: boolean = false;
  isUpdatingRollDirection: boolean = false;
  defaultCustomerRollDirection!: number;
  isGeneratingPrice: boolean = false;

  /* Methods */
  refreshDebounce() {
    this.debounce = true;
    setTimeout(() => {
      this.debounce = false;
    }, 1000);
  }
  async createPO() {
    const response = await OrdersService.CreateDefaultPo();
    this.selectedPO = response.data;
  }
  doesPOExists() {
    return this.customerOrders.some(p => p == this.selectedPO);
  }

  async waitForSearch() {
    this.recalculatePrice();
  }

  async onAddressChange() {
    this.shouldShowAlert = false;
    this.selectedRollDirection = this.orderlineToReorder.Address.IsCustomerPickup
      ? this.defaultCustomerRollDirection
      : this.orderlineToReorder.Address.Options.RollDirectionId;
    this.shouldShowAlert = this.selectedRollDirection === 10;
  }
  async recalculatePrice() {
    this.isLoading = true;
    if (this.shouldRecalculate()) {
      await this.generateNewOrderline();
      await this.GeneratePricingData();
    }
    this.isLoading = false;
  }
  onUpdateRollDirectionId(rollDirectionId: number) {
    this.selectedRollDirection = rollDirectionId;
  }
  async generateNewOrderline() {
    this.isGeneratingOrderlineDefaults = true;
    const model = ConversionHelper.generateNewOrderlineModel(
      this.item.Id,
      Number(this.orderlineToReorder.Quantity),
      this.orderlineToReorder.Address.Id,
      this.selectedPO
    );
    if (!this.currentCustomer) {
      await this.getCurrentCustomerStore();
    }
    model.RollDirectionId = this.selectedRollDirection!;
    const response = await OrdersService.GenerateOrderlineDefaults(model);
    const newOrderline = new OrderlineDetails(response.data);
    this.orderlines = [newOrderline];

    this.orderlines[0].OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
    this.orderlines[0].OrderLineNumber =
      (this.orderlines.length >= 1
        ? Math.max.apply(
            Math,
            (this.orderlines as OrderlineDetails[]).map(ol => {
              return ol.OrderLineNumber;
            })
          )
        : 0) + 1;
    this.orderlineToReorder = newOrderline;
    // this.refreshOriginalOrderline();
    this.isGeneratingOrderlineDefaults = false;
  }
  async GeneratePricingData() {
    if (this.orderlines[0]) {
      this.isGeneratingPrice = true;
      const response = await OrdersService.CalculatePricingData(
        this.orderlines.map((ol: any) => ConversionHelper.convertOrderlineToAddModel(ol))
      );
      const orderlines = response.data.map((ol: any) => new OrderlineDetails(ol));
      this.orderlines = orderlines;
      const orderline = orderlines.find(
        (ol: { Id: string }) => ol.Id == this.orderlineToReorder.Id
      );
      this.orderlineToReorder = orderline;
      let rushOption = this.rushOptions.find(
        r => r.PublicId == this.orderlineToReorder.RushOption.PublicId
      );
      if (rushOption) {
        this.orderlineToReorder.RushOption = rushOption;
      } else {
        this.orderlineToReorder.RushOption = this.rushOptions[0];
      }
      // this.refreshOriginalOrderline();
      this.resetFees();
      response.data.forEach((ol: any) => {
        this.extendedPrice += ol.Quantity * ol.PriceEach;
        this.setUpFee += ol.SetupCosts;
        this.variableDataFee += ol.Quantity * ol.VariableDataPriceEach;
        this.rushFee = ol.RushFee;
        this.extraRollFee = ol.HandApplyFee;
      });
      this.isGeneratingPrice = false;
    } else {
      this.resetFees();
    }
  }
  resetFees() {
    this.extendedPrice = 0;
    this.setUpFee = 0;
    this.variableDataFee = 0;
    this.rushFee = 0;
    this.extraRollFee = 0;
  }
  async placeOrder() {
    this.isPlacingOrder = true;
    try {
      const data = await PaymentService.GetCustomerPaymentProfiles();
      const defaultPayment = data.data.filter((p: any) => p.IsDefault == true);
      let model = {
        Orderlines: this.orderlines.map((ol: OrderlineDetails) =>
          ConversionHelper.convertOrderlineToAddModel(ol)
        ),
        PO: this.selectedPO,
        State: 'New',
        ShipToId: this.selectedAddress.IsCustomerPickup ? 6 : 4,
        PaymentMethodProfileId: defaultPayment[0].PaymentProfileId,
        OrderType: 'Reorder'
      };
      const orderResponse = await OrdersService.PlaceOrder(model);
      const newOrderline: Orderline[] = orderResponse.data.map(
        (r: any) => new Orderline(r)
      );
      this.$store.dispatch('items/refreshItem', this.item.Id);
      if (this.orderlines[0]) {
        let orderlinesWithAddedOl = [...newOrderline, ...this.loadedOrderlines];
        this.setOrderlines(orderlinesWithAddedOl);
      }
      this.closeDialog();
      SetSnackBar('Reordered successfully');
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isPlacingOrder = false;
  }
  async QuickOrder() {
    if (this.selectedPO) {
      try {
        const response = {
          orderline: this.orderline,
          selectedPO: this.selectedPO
        };
        await this.$emit('response', response);
      } catch (err) {
        SetSnackBar(
          'Something went wrong placing your order, please refresh your page, or contact techincal support if error persists'
        );

        //TODO: notify snackbar
      }
    }
  }
  async getAllCustomerOrder() {
    try {
      const response = await OrdersService.GetAllCustomerOrderPOs();
      this.entries = response.data;
    } catch (err) {
      SetSnackBar(
        'There was an error, please refresh your page, or contact techincal support if error persists'
      );
    } finally {
      this.isSearching = false;
    }
  }
  async getAllCustomerAddresses() {
    try {
      const response = await CustomerService.GetShippingAddressesFull();
      this.addresses = response.data;
    } catch (err) {
      SetSnackBar(
        'There was an error loading your shipping addresses, please refresh your page, or contact techincal support if error persists'
      );
    }
  }
  async getCurrentCustomer() {
    if (!this.currentCustomer.Name) await this.getCurrentCustomerStore();
    let currentCustomer = this.currentCustomer;
    this.defaultCustomerRollDirection = currentCustomer.Options.RollDirection.Position;
  }
  async LoadRushFeeValues() {
    if (this.orderline) {
      const response = await OrdersService.GetRushFeeValues(
        ConversionHelper.convertOrderlineToAddModel(this.orderline)
      );
      this.rushOptions = response.data.filter((d: any) => d.IsAvailable);
      this.rushOptions = this.rushOptions.map(r => new RushOption(r));
      this.setDefaultRushOptions();
    }
  }
  setDefaultRushOptions() {
    if (
      this.orderlineToReorder.RushOption &&
      this.orderlineToReorder.RushOption.PublicId &&
      this.rushOptions[0]
    ) {
      this.orderlineToReorder.RushOption = this.rushOptions.find(
        r => r.PublicId === this.orderlineToReorder.RushOption.PublicId
      )!;
    } else if (!this.orderlineToReorder.RushOption.PublicId && this.rushOptions[0]) {
      this.orderlineToReorder.RushOption = this.rushOptions[0];
    } else {
      //Do Nothing
    }
  }
  canPlaceOrder() {
    return (
      this.orderlineToReorder &&
      this.orderlineToReorder.Quantity &&
      this.orderlineToReorder.RushOption &&
      this.orderlineToReorder.Address.Id &&
      !this.isGeneratingPrice &&
      !this.isGeneratingOrderlineDefaults &&
      !this.debounce &&
      this.selectedRollDirection &&
      !this.shouldShowAlert
    );
  }

  async showDialog() {
    this.data = ConversionHelper.convertQuickDialog(this.item);
    this.getCurrentCustomer();
    this.getAllCustomerOrder();
    await this.getAllCustomerAddresses();
    this.LoadRushFeeValues();

    this.createPO();
    // this.orderlineToReorder = Clone(this.orderline);
    // this.originalOrderline = Clone(this.orderline);
    this.selectedAddress = this.orderline.Address;
    this.selectedRollDirection = Number(this.orderline.RollDirection.Id);
    this.rushOption = this.orderline.RushOption;
    this.dialog = true;
  }
  closeDialog() {
    this.rushOption = new RushOption();
    this.selectedPO = null;
    this.selectedRollDirection = null;
    this.selectedAddress = null;
    this.shouldShowAlert = false;
    this.dialog = false;
  }
  closeAlert() {
    this.shouldShowAlert = false;
  }
  shouldRecalculate() {
    return (
      !!this.selectedPO &&
      !!this.orderlineToReorder.Address.Id &&
      Number(this.orderlineToReorder.Quantity) > 0
    );
  }
  /* Computed */
  get customerOrders(): string[] {
    return this.entries.map(entry => {
      const Description =
        entry.length > this.descriptionLimit
          ? entry.slice(0, this.descriptionLimit) + '...'
          : entry;

      return Description;
    });
  }

  get fields() {
    if (!this.selectedPO) return [];

    return this.selectedPO;
  }
  get calculateSubTotal() {
    return (
      this.extendedPrice +
      this.setUpFee +
      this.variableDataFee +
      this.rushFee +
      this.extraRollFee
    ).toFixed(2);
  }
  /* Mounted */
  /* Created */
  async created() {}
}
